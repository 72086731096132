import { FunctionComponent, HTMLAttributes, ReactElement } from "react";
import { Container, Grid, Typography } from "@mui/material";
import Image from "next/image";
import { styled } from "@mui/system";
import { fonts } from "~/theme";

import avatar from "./avatar.png";

interface Properties extends HTMLAttributes<HTMLElement> {}

const StyledContainer = styled(Container)(({ theme }) => ({
    marginTop: "72px",
    marginBottom: "72px",
    [theme.breakpoints.down("sm")]: {
        marginTop: "10px",
        marginBottom: "64px",
    },
}));

const Section1 = styled(Typography)(({ theme }) => ({
    ...fonts.bannerBigText,
    [theme.breakpoints.down("sm")]: {
        fontSize: "32px",
        lineHeight: "34px",
    },
}));
const Section2 = styled(Typography)(({ theme }) => ({
    ...fonts.bannerBigTextSM,
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "32px",
    lineHeight: "39px",
    marginTop: "29px",
    [theme.breakpoints.down("sm")]: {
        fontSize: "22px",
        lineHeight: "28px",
        marginTop: "8px",
    },
}));
const Section3 = styled(Typography)(({ theme }) => ({
    ...fonts.bannerSmallTextSM,
    marginTop: "24px",
    [theme.breakpoints.down("sm")]: {
        fontSize: "13px",
        lineHeight: "17px",
        marginTop: "16px",
    },
}));
const Exhibition = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    [theme.breakpoints.down("sm")]: {
        flexDirection: "row",
        alignItems: "center",
        marginBottom: "62px",
    },
}));
const ExhibitionAuthor = styled("div")(({ theme }) => ({
    display: "flex",
    width: "220px",
    fontSize: "16px",
    lineHeight: "24px",
    border: "1px solid rgba(0, 0, 0, 0.08)",
    borderRadius: 8,
    overflow: "hidden",
    alignItems: "center",
    "& > span": {
        marginRight: "16px !important",
    },
    [theme.breakpoints.down("sm")]: {
        fontSize: "13px",
        lineHeight: "17px",
        marginRight: "46px",
        width: "163px",
    },
}));

const ExhibitionDetails = styled(Typography)(({ theme }) => ({
    ...fonts.bannerSmallTextSM,
    display: "flex",
    flexDirection: "column",
    width: "220px",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.15px",
    marginTop: "12px",
    [theme.breakpoints.down("sm")]: {
        marginTop: "0",
        width: "auto",
        fontSize: "13px",
        lineHeight: "17px",
    },
}));

export const Biography: FunctionComponent<Properties> = ({
    className,
    ...rest
}: Properties): ReactElement => {
    return (
        <StyledContainer
            data-testid="biography-container"
            className={className}
            {...rest}
        >
            <Grid container wrap="wrap-reverse">
                <Grid item sm={9}>
                    <Section1 data-testid="artuner-test-section-1">
                        Paul Kneale: Free Software
                    </Section1>

                    <Section2 data-testid="artuner-test-section-2">
                        “Free Software”, a body of work inspired by the YouTube
                        genre of putting unusual things in a microwave.
                    </Section2>

                    <Section3 data-testid="artuner-test-section-3">
                        Over the past decade, Paul Kneale has been working in
                        the digital art space. Kneale’s online conceptual works
                        have complemented his painting and sculptural practice,
                        creating an essential dialogue between them.
                        <br />
                        <br />
                        Kneale’s ongoing project, “Free Software”, has been
                        exhibited globally across museums. “Free Software” has
                        evolved into NFTs, using the blockchain as a medium. The
                        project uses the notion of a generative practice to
                        create beautiful unique works; with a dual digital and
                        physical outcome.
                        <br />
                        <br />
                        Kneale’s work draws attention to NFTs as the arrival
                        point of years of digital exploration. This project
                        allows for a deeper connection with established and
                        historical art practices. While the process is key, the
                        power of the images is at the fore.
                        <br />
                        <br />
                        Collectors who purchase three works will also receive a
                        signed physical edition by the artist. One top buyer
                        receives unlockable content - a one-on-one session with
                        the artist to produce a collaborative “Free Software”
                        piece
                    </Section3>
                </Grid>
                <Grid item sm={3}>
                    <Exhibition>
                        <ExhibitionAuthor>
                            <Image
                                width={56}
                                height={56}
                                src={avatar}
                                alt="Paul Kneale"
                                data-testid="author-image"
                            />
                            Paul Kneale
                        </ExhibitionAuthor>

                        <ExhibitionDetails>
                            Exhibition: <br />
                            ARTUNER <br />
                            May 12, 2022
                        </ExhibitionDetails>
                    </Exhibition>
                </Grid>
            </Grid>
        </StyledContainer>
    );
};
